import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUserName } from "../features/auth/authSlice";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Calendar from "./Calendar"; 
import Square from "./Square";

export default function Dashboard() {
  const dispatch = useDispatch();
  const username = useSelector(selectUserName);
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [calendarInUse, setCalendarInUse] = useState(false);

  useEffect(() => {
    getTodaysDate();
    setInterval(getTodaysDate, 60000);
  }, []);
  
  const mainDash =  
    <>
      <Row>
        <Col className='mt-4 window text-center'>
          <p className='engraved add-me'>Add<br/> HUB</p>
        </Col>
        <Col className='mt-4 window text-center'>
          <p className='engraved add-me'>Add<br/> Thermo</p>
        </Col>
      </Row>
      <Row>
        <Col className='window text-center'>
          <Square username={username}/> 
        </Col>
      </Row>
      <Row>
        <Col className={calendarInUse ? 'window text-center in-use' : 'window text-center'}>
          <Calendar callbackToActivate={setCalendarInUse} username={username}/>
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          <Button className='hub-orange-background w-100 mt-4' as="input" type="submit" onClick={() => {dispatch(logout())}} value="Log Out" size="lg"/>  
        </Col>
      </Row>
    </>

  const getTodaysDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    let todayDate = mm + '/' + dd + '/' + yyyy;
    setCurrentDate(today.toLocaleDateString('us', {weekday: 'long'}) + ' ' + todayDate );
    setCurrentTime(today.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'}));
  }

  return (
    <Container  id = 'auth' className='bkgnd'>
      <Row className='mt-5'>
        <Col className='text-center'>
          <h1 className='pt-2 engraved'>OGOHUB</h1>
          <h1>{currentDate}</h1>
          <h1>{currentTime}</h1>
        </Col>
      </Row>
      {mainDash}
    </Container>
  );
}

