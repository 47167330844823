import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {useGetUserApisQuery, useCreateApiAuthMutation} from "../app/api";
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import dompurify from 'dompurify'; 

export default function Calendar({callbackToActivate, username}) {

  const {data: apiData} = useGetUserApisQuery({username: username});
  const [addApiValue] = useCreateApiAuthMutation();

  const scriptSrcGoogle = "https://accounts.google.com/gsi/client";

  /** get User Specific API settings **/
  const [accessToken, setAccessToken] = useState(null);

  const [showButton, setShowButton] = useState('');
  const [showEvent, setShowEvent] = useState(null);

  useEffect(() => {
    if (apiData) {
      let googleType = apiData.filter(t => t.api_type == 'GOOGLE_CAL')?.sort((a,b) => a-b).reverse(); 
      if (googleType) {
        setAccessToken(googleType[0]?.api_value);
      }
    }
  }, [apiData]);

  useEffect(() => {
    if (accessToken) {
      listUpcomingEvents();
    }
  }, [accessToken]);

  /** GOOGLE API SETTINGS **/
  const config = {
    "clientId": "733667762216-t88q1ug1njlm7rgsdpb2nsbql7kug1td.apps.googleusercontent.com",
    "apiKey": "AIzaSyCTsJD5iRseGdtX1JTMmu_sUFKicGzRcJk",
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
  }
 
  /** CLIENT LOAD AFTER WINDOW IS READY **/
  useEffect (() => 
    { handleClientLoad();}
  , []);

  const [thisGoogle, setThisGoogle] = useState(null);

   const handleClientLoad = () =>  {
   
    const scriptGoogle = document.createElement("script");
    scriptGoogle.src = scriptSrcGoogle;
    scriptGoogle.async = true;
    scriptGoogle.defer = true;
    document.body.appendChild(scriptGoogle);
    scriptGoogle.onload = async () => {
      setThisGoogle(window.google);
    };
  }

  /** Once Google is ready, (thisGoogle lives) authorize **/
  
  const authenticateGoogle = () => {
    
      let client = thisGoogle.accounts.oauth2.initTokenClient({
          apiKey: config.apiKey, 
          discoveryDocs: config.discoveryDocs,
          redirect_uri: 'https://foodtruck-hub.com',
          include_granted_scopes: 'true',
          response_type: 'token',
          scope: "https://www.googleapis.com/auth/calendar.readonly",
          access_type: 'offline',
          client_id: "733667762216-t88q1ug1njlm7rgsdpb2nsbql7kug1td.apps.googleusercontent.com",
          plugin_name: "ogohub",
          prompt: "",
          callback: (tokenResponse) => {
            setAccessToken(tokenResponse.access_token);
            
            addApiValue({username: username, api_type: 'GOOGLE_CAL', api_value: tokenResponse.access_token});
          }
        });
      client.requestAccessToken();
  }



  const [events, setEvents] = useState(null);

  const listUpcomingEvents = async () => {
    
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        let eventInfo = JSON.parse(this.response);
        setEvents(eventInfo.items);
        callbackToActivate(true);
        console.log('events', events);
      }
      else {
        if (this.readyState == 4 && this.status != 200) {
          console.log('token error:', this);
          setAccessToken(null);
        }
      }
    };
    let nowTime = (new Date).toISOString();
    console.log('nowTime', nowTime);
    xhr.open('GET', 'https://www.googleapis.com/calendar/v3/calendars/primary/events?maxResults=20&orderBy=startTime&singleEvents=true&timeMin=' + nowTime);
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xhr.send();
  }

  const isEventToday = (t) => {
    const today = new Date();
    const eventDate = new Date(t.start.dateTime);
    let daysUntil = '';
    let stop = false;

    if (today.getDate() == eventDate.getDate() && today.getMonth() == eventDate.getMonth() && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = 'TODAY!!!';
      stop = true;
    }
    if (!stop && today.getMonth() == eventDate.getMonth() && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = eventDate.getDate() - today.getDate() + ' days Until..';
      stop = true;
    }
    if (!stop && today.getFullYear() == eventDate.getFullYear()) {
      daysUntil = 'Not this month' 
      stop = true;
    }
    if (!stop ) {
      daysUntil = 'Not this year' 
      stop = true;
    }
    //return evenColate.getTime() - today.getTime();
    return (daysUntil);
  }

  const eventList = () => {
    return events.map(t => (
      <Row key={t.id}>
        <hr/>
        <Row className='mb-2'>
          <Col xs={'12'} id={t.id} onClick={()=>{setShowButton(t.id)}}>
            <p className='leftside mb-1'>{isEventToday(t)}</p>
            {t.id == showButton ?
              <Button onClick={()=>{setShowEvent(t.id)}} className='rightside right-date'>View</Button> 
              :
              <p className='rightside mb-0 right-date'>{new Date(t.start.dateTime).toLocaleDateString()}</p>
            }
          </Col>
          <Col >
            <b>{new Date(t.start.dateTime).toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} {t.summary}</b> 
          </Col>
        </Row>
      </Row>
    ))
  }

  const mapDom = (domString) => {
    return (<div dangerouslySetInnerHTML={{__html: dompurify.sanitize(domString.replace('\n\n\n','<br/>').replace('\n','<br/'), {FORCE_BODY:true})}} />);
  }

  const eventView = ()=> {
    if (!events) {
      <p>No Events to show</p>
    }
    else {
      if (showEvent) {
        const thisEvent = events.find(t => t.id == showEvent);
        return (
          <>
            <h1 className='window-header mt-2'>{thisEvent.summary}</h1>
            <h1 className='window-header mt-2'>by {thisEvent.organizer.email}</h1>
            <Container className='text-left p-2'>
              <p><b>Starting:</b></p>
              <p>{thisEvent.start.dateTime}</p>
              <p><b>Ending:</b></p>
              <p>{thisEvent.end.dateTime}</p>
              <p><b>Details:</b></p>
              {mapDom(thisEvent.description)} 
              <br/>
              <Button className='rightside' size={'lg'} onClick={()=>{setShowEvent(null); setShowButton('');}}>Close</Button>
            </Container>
          </>
        )
      }
      else {
        return (
          <>
            <h1 className='window-header mt-2'>Upcoming Events</h1>
            <Container className='text-left p-2'>
              {eventList()}
            </Container>
          </>
        )
      }
    }
  }

  return (
    <div id = 'calendar'>
      {!accessToken && <p onClick={()=>{authenticateGoogle()}} className='engraved add-me'>Add<br/> Calendar</p>}
      {eventView()}      
    </div>
  );
}

Calendar.propTypes = {
  callbackToActivate: PropTypes.func,
  username: PropTypes.string,
}
