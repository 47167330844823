import React, { useState, useEffect } from "react";
import { useLoginMutation, useCreateUserMutation } from "../../app/api";
import { saveCredentials } from "./authSlice";
import LoginError from "./LoginError";
import { useNavigate } from "react-router-dom";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorStatusCode, setErrorStatusCode] = useState(undefined);
  const [isError, setIsError] = useState(false);

  const [showSignup, setShowSignup] = useState(false);

  const [login, { isLoading, logIsError }] = useLoginMutation();
  
  const [createUser ] = useCreateUserMutation();

  const canLogin = !isLoading && username.length > 0 && password.length > 0;
  const canSignUp = !isLoading && ( (!showSignup ) || ( !isLoading && showSignup && username.length > 0 && password.length > 0 && confirmPassword.length > 0 ) ) ;

  const navigate = useNavigate();

  useEffect(() => {
     if (logIsError) {
      setIsError(true);
     } 
  }, logIsError);

  async function onLogin(event) {
    setErrorStatusCode(undefined);
    setIsError(false);
    try {
      // Prevent the form submission
      event.preventDefault();

      // Submit the login attempt
      const result = await login({ username, password }).unwrap();

      // Reached here when the request is successful. The access token and
      // username have been updated in the local state by authSlice.

      // Save the access token and username to local storage
      console.log('the role is: ', result.permissions[0]);
      if (result.permissions && result.permissions[0] == 'PENDING') {
        console.log('setting status code');
        setErrorStatusCode(222);
      }
      else {
        console.log('saving auth');
        saveCredentials(result.access_token, username);
      }
    } catch (err) {
      // Set the error status code, if available, for the LoginError component
      setErrorStatusCode(err.status);
    }
  }

  const submitSignUp = async () => {
    if (password != confirmPassword) {
      setErrorStatusCode(123);
    }
    else {
      const {error: createError} = await createUser({username: username, password: password});
      if (createError) {
        if (createError.status == 400) {
          setErrorStatusCode(111);
        }
        else {
          setErrorStatusCode(createError.status);
        }
      }
      else {
          setShowSignup(false);
          setErrorStatusCode(222);
      }
    }
  }

  const runShowSignUp = () => { 
      setShowSignup(true); 
      setErrorStatusCode(undefined); 
      setIsError(false);
    }
  

  return (
    <Container  id = 'auth' className=''>
      <Row>
        <Col className='text-center w-100 mt-5 mb-3'>
          <div onClick={()=>navigate('/home')}>
            <h1 className='pt-2 hub-orange fw-bold home-logo link'>OGO<br/>HUB</h1>
          </div>
          {isLoading && <div className="spinner-border hub-orange" role="status"><span className="sr-only"/></div>}
          <h4 className='engraved smaller mt-5'>{!isLoading && (showSignup ? "SIGN UP" : "LOG IN")}</h4>
        </Col>
      </Row>
      <Row className='justify-content-sm-center flex-fill gy-5'>
        <Col className='text-center w-100'>
      <FloatingLabel controlId='floatingInput' label='Email address' className='mb-3'>
        <Form.Control
          type="text"
          autoComplete="username"
          placeholder="name@example.com"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </FloatingLabel>
      <FloatingLabel controlId='floatingPassword' label='Password' className='mb-3'>
        <Form.Control
          type="password"
          autoComplete="current-password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FloatingLabel>
      {showSignup && 
        <FloatingLabel controlId='floatingConfirmPassword' label='Confirm Password' className='mb-3'>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FloatingLabel>
      }
      </Col></Row>
      {(isError || errorStatusCode) &&  (
        <LoginError className='red' errorStatusCode={errorStatusCode} />
      )}
      {!showSignup && 
        <>
        <Row className='justify-content-sm-center flex-fill gy-5'>
          <Col className='text-center w-100'>
         <div className="d-grid gap-2">
           <Button className='hub-orange-background' as="input" type="submit" onClick={onLogin} disabled={!canLogin} value="Login" size="lg"/>
         </div>
         </Col>
         </Row>
         <Row className='mt-4'>
           <Col className=''>
             <hr/>
           </Col>
           <Col xs={1} className=" align-items-center ">
             <p className="text-center fw-bold mb-0 text-muted">OR</p>
           </Col>
           <Col className=''>
             <hr/>
           </Col>
         </Row>
        </>
      }
      <Row>
        <div className="d-grid gap-2 mt-2">
          <Button onClick={()=> {showSignup ? submitSignUp() : runShowSignUp()}} className='mt-2' as="input" type="submit" value="Sign Up" size="lg" disabled={!canSignUp}/>
        </div>
      </Row>
      {showSignup && 
      <>
       <Row className='mt-4'>
           <Col className=''>
             <hr/>
           </Col>
           <Col xs={1} className=" align-items-center ">
             <p className="text-center fw-bold mb-0 text-muted">OR</p>
           </Col>
           <Col className=''>
             <hr/>
           </Col>
         </Row>
      <Row>
        <div className="d-grid gap-2 mt-2">
          <Button onClick={()=> {setShowSignup(false); setErrorStatusCode(undefined); setIsError(false);}} className='mt-2 hub-orange-background' as="input" type="submit" value="Log In" size="lg"/>
        </div>
      </Row>
      </>
      }
    </Container>
  );
}
